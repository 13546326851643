<template>
  <v-card elevation="4" class="mb-8">
    <v-card-title class="pb-6 d-flex flex-row justify-space-between">
      <v-skeleton-loader width="100%" type="heading" v-if="loading" />
      <template v-else>
        <h6>{{ $t('heading.hosting.summary.planCard.title') }}</h6>
        <slot name="action" />
      </template>
    </v-card-title>

    <v-card-text
      class="d-flex align-center justify-space-between space-indicator-row"
    >
      <template v-for="(item, index) in availableSpace">
        <hosting-available-space-card-skeleton :key="index" v-if="loading" />
        <hosting-available-space-card
          v-else
          :key="index"
          :title="$t(`heading.hosting.summary.planCard.usage.${item.title}`)"
          :icon="item.icon"
          :available="item.available"
          :availableText="item.availableText"
          :value="item.value"
          :valueText="item.valueText"
        />
      </template>
    </v-card-text>

    <v-divider class="mt-6" />

    <v-card-text class="">
      <div class="summary-data">
        <template v-if="loading">
          <div v-for="item in 6" :key="item">
            <v-skeleton-loader class="mb-3" type="text" width="120px" />
            <div class="summary-data__data mb-2">
              <v-skeleton-loader type="text" width="150px" />
              <v-skeleton-loader type="text" width="100px" />
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="summary-data__content"
            v-for="(item, index) in usageData"
            :key="`${index}-${item.name}`"
          >
            <div v-if="item.name === 'bandwidth' || service.getUserPrivileges(service.privileges, item.privilege)">
              <p class="mb-2 summary-data__name">{{ $t(`heading.hosting.summary.planCard.usage.${item.name}`) }}</p>
              <div class="summary-data__data">
                <span class="p-2">
                  <v-icon
                    v-if="item.valueText === '∞'"
                    size="20"
                    color="gray darken-1"
                  >
                    $infiniteIcon
                  </v-icon>
                  <b v-else>{{ item.valueText }}</b>
                  /
                  <v-icon
                    v-if="item.maxValueText === '∞'"
                    size="20"
                    color="gray darken-1"
                  >
                    $infiniteIcon
                  </v-icon>
                  <template v-else>
                    {{ item.maxValueText }}
                  </template>
                  <template v-if="item.url">
                    <router-link :to="item.url">
                      <v-btn x-small text color="info" class="px-2">
                        <span class="font-weight-bold p-2">{{ $t('button.manage') }}</span>
                      </v-btn>
                    </router-link>
                  </template>
                </span>

                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs" class="py-2">
                      <progress-chart
                        style="max-width: 100px"
                        :maxValue="item.maxValue"
                        :value="item.value"
                        v-if="item.maxValueText !== '∞'"
                      />
                      <v-icon size="20" color="gray darken-1">
                        ${{ item.icon }}
                      </v-icon>
                    </div>
                  </template>
                  <template v-if="item.maxValueText === '∞'">
                    {{ $t('tooltip.hosting.summary.planCard.infinite') }}
                  </template>
                  <template v-else>
                    <span class="header--text font-weight-600">
                      {{ Math.ceil((item.value / item.maxValue) * 100) }}%
                    </span>
                    <span class="base--text font-weight-normal">
                      <i18next :translation="$t('tooltip.hosting.summary.planCard.usage')">
                        <template #percent>
                          <span class="header--text font-weight-600">
                            {{ Math.ceil((item.value / item.maxValue) * 100) }}%
                          </span>
                        </template>
                      </i18next>
                    </span>
                  </template>
                </v-tooltip>
              </div>
            </div>
          </div>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ProgressChart from "../charts/ProgressChart.vue";
import HostingAvailableSpaceCard from "./HostingAvailableSpaceCard.vue";

export default {
  components: { ProgressChart, HostingAvailableSpaceCard },
  props: {
    usageData: Array,
    loading: Boolean,
    availableSpace: Array,
    service: Object
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.summary-data {
  display: flex;
  flex-direction: column;
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &__data {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.v-btn:not(.v-btn--round).v-size--x-small {
  height: 22px;
}

@media (max-width: 1400px) and (min-width: 1183px) {
  .space-indicator-row {
    flex-direction: column;
    align-items: flex-start !important;
    > div:not(:first-child) {
      margin-top: 8px;
    }
  }
}
@media (max-width: 500px) {
  .space-indicator-row {
    flex-direction: column;
    align-items: flex-start !important;
    > div:not(:first-child) {
      margin-top: 8px;
    }
  }
}
</style>