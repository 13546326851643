<template>
  <v-card elevation="4" class="mb-8">
    <v-card-title class="pb-6 d-flex flex-row justify-space-between">
      <h6 class="font-weight-bold">
        <i18next :translation="$t('heading.hosting.summary.nameserverCard.mainDomain.headline')">
          <template #domain>
            <span class="font-weight-light ml-1">
              {{ splitName()[1] }}
            </span>
          </template>
        </i18next>
      </h6>

      <!-- <v-btn x-small text color="info">
        <span class="font-weight-600"> Edit </span>
      </v-btn> -->
    </v-card-title>
    <v-card-text v-if="!service"></v-card-text>
    <v-card-text
      v-else-if="
        service.dns_server_enabled
        && service.plan_settings.dns_nameservers_notification_enabled
        && nameservers.length
      "
    >
      <h6>
        {{ $t('heading.hosting.summary.nameserverCard.nameservers.headline') }}
        <v-progress-circular
          v-if="resolvingNs"
          :size="20"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-menu
          v-else-if="unresolvedDomains.length || misconfiguredDomains.length"
          transition="custom-tooltip"
          open-delay="150"
          bottom
          z-index="99"
          offset-overflow
          nudge-bottom="24px"
          origin="top center"
          min-width="400px"
          max-width="400px"
          :nudge-left="$vuetify.rtl ? '-180px' : '180px'"
          class="ml-2"
          :close-on-content-click="false"
          open-on-hover
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small icon text color="error" v-on="on" v-bind="attrs">
              <v-icon color="error" size="20"> $alertcircle</v-icon>
            </v-btn>
          </template>
          <div v-if="unresolvedDomains.length" class="px-4 py-4">
            <p>{{ $t('tooltip.hosting.summary.nameservers.notResolve') }}:</p>
            <ul>
              <nameserver-list-item
                v-for="(item, index) in unresolvedDomains"
                :key="item + index"
                :item="item"
                :index="index"
              />
            </ul>
          </div>
          <div v-if="misconfiguredDomains.length" class="px-4 py-4">
            <p>{{ $t('tooltip.hosting.summary.nameservers.notPointed') }}:</p>
            <ul>
              <nameserver-list-item
                v-for="(item, index) in misconfiguredDomains"
                :key="item + index"
                :item="item"
                :index="index"
              />
            </ul>
          </div>
        </v-menu>
      </h6>

      <p class="pt-1 mb-2">
        {{ $t('heading.hosting.summary.nameserverCard.nameservers.text') }}
      </p>

      <ul>
        <template v-for="(item, index) in nameservers">
          <nameserver-list-item
            v-if="!loading"
            :key="`${item} + ${index}`"
            :item="item"
            :index="index"
          />
          <v-skeleton-loader
            :key="`${item} + ${index}`"
            v-else
            type="text"
            width="200"
            class="mt-2"
          />
        </template>
      </ul>
    </v-card-text>
    <v-card-text v-else-if="dnsRecords.length">
      <h6>
        {{ $t('heading.hosting.summary.nameserverCard.dnsRecords.headline') }}
        <v-progress-circular
          v-if="resolvingNs"
          :size="20"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-menu
          v-else-if="unresolvedDomains.length || misconfiguredDomains.length"
          transition="custom-tooltip"
          open-delay="150"
          bottom
          z-index="99"
          offset-overflow
          nudge-bottom="24px"
          origin="top center"
          min-width="400px"
          max-width="400px"
          :nudge-left="$vuetify.rtl ? '-180px' : '180px'"
          content-class="v-tooltip"
          class="ml-2"
          :close-on-content-click="false"
          open-on-hover
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small icon text color="error" v-on="on" v-bind="attrs">
              <v-icon color="error" size="20"> $alertcircle</v-icon>
            </v-btn>
          </template>
          <div v-if="unresolvedDomains.length" class="px-4 py-4">
            <p>{{ $t('tooltip.hosting.summary.dns.notResolve') }}:</p>
            <ul>
              <nameserver-list-item
                v-for="(item, index) in unresolvedDomains"
                :key="item + index"
                :item="item"
                :index="index"
              />
            </ul>
          </div>
          <div v-if="misconfiguredDomains.length" class="px-4 py-4">
            <p>{{ $t('tooltip.hosting.summary.nameservers.notResolve') }}:</p>
            <ul>
              <nameserver-list-item
                v-for="(item, index) in misconfiguredDomains"
                :key="item + index"
                :item="item"
                :index="index"
              />
            </ul>
          </div>
        </v-menu>
      </h6>

      <p class="pt-1 mb-2">
        {{ $t('heading.hosting.summary.nameserverCard.dnsRecords.text') }}:
      </p>

      <ul>
        <template v-for="(item, index) in dnsRecords">
          <nameserver-list-item
            v-if="!loading"
            :key="`dnsRecord${index}`"
            :item="item"
            :index="index"
          />
          <v-skeleton-loader
            :key="`dnsRecord${index}`"
            v-else
            type="text"
            width="200"
            class="mt-2"
          />
        </template>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
import NameserverListItem from "../NameserverListItem.vue";
import Api from "@/apis/Api";

export default {
  components: {
    NameserverListItem,
  },
  data: function () {
    return {
      tooltipOpen: 99,
      resolvingNs: true,
      unresolvedDomains: [],
      misconfiguredDomains: [],
      dnsRecords: []
    };
  },
  props: {
    service: Object,
    serverAccounts: Array,
    loading: Boolean,
  },
  computed: {
    nameservers() {
      let nameservers = [];
      this.serverAccounts.forEach((serverAccount) => {
        serverAccount.details.nameservers.forEach((nameserver) => {
          nameservers.push(nameserver);
        })
      })
      return nameservers;
    },
  },
  created() {
    if (this.service) {
      // this.resolveNs();
      this.resolveA();
    }
  },
  watch: {
    service: function () {
      // this.resolveNs();
      this.resolveA();
    },
  },
  methods: {
    // resolveNs() {
    //   this.resolvingNs = true;
    //   Api.cached()
    //     .get(`/server-accounts/${this.serverAccount.id}/domains`)
    //     .then((response) => {
    //       let domains = response.data.data;
    //       for (const domain of domains) {
    //         if (domain.type == "sub") {
    //           continue;
    //         }
    //         Api.dns()
    //           .nsResolve(domain.domain)
    //           .then((nameservers) => {
    //             if (nameservers.length === 0) {
    //               this.unresolvedDomains.push(domain.domain);
    //               return;
    //             }
    //             for (const ns of nameservers) {
    //               if (!this.nameservers.includes(ns)) {
    //                 this.misconfiguredDomains.push(domain.domain);
    //                 return;
    //               }
    //             }
    //           });
    //       }
    //     })
    //     .finally(() => {
    //       this.resolvingNs = false;
    //     });
    // },
    resolveA() {
      this.unresolvedDomains = [];
      this.misconfiguredDomains = [];
      this.dnsRecords = [];
      this.resolvingNs = true;

      Promise.all(this.serverAccounts.map(serverAccount => {
        return Api.cached()
          .get(`/server-accounts/${serverAccount.id}/domains`)
          .then((response) => {
            let domains = response.data.data;
            const domainPromises = domains.map(domain => {
              if (domain.type === "sub" || ('has_dns_zone' in domain && !domain.has_dns_zone)) {
                return Promise.resolve();
              }

              return Api.dns()
                .resolveARecord(domain.domain)
                .then((result) => {
                  if (result === null) {
                    this.unresolvedDomains.push(domain.domain);

                    this.dnsRecords.push(`${domain.domain} A ${serverAccount.details.ip_address}`);
                    this.dnsRecords.push(`www.${domain.domain} CNAME ${domain.domain}`);

                  } else if (result !== serverAccount.details.ip_address) {
                    this.misconfiguredDomains.push(domain.domain);

                    this.dnsRecords.push(`${domain.domain} A ${serverAccount.details.ip_address}`);
                    this.dnsRecords.push(`www.${domain.domain} CNAME ${domain.domain}`);
                  }
                });
            });
            return Promise.all(domainPromises);
          });
      })).finally(() => {
        this.resolvingNs = false;
      });
    },
    clearTooltips($event) {
      this.tooltipOpen = 99;
      $event.target.blur();
    },
    splitName: function () {
      if (this.service && this.service.name) {
        const split = this.service.name.split(" - ");
        return [split[0], split[1]];
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  .v-card__text {
    -webkit-line-clamp: unset;
  }
}

ul {
  li {
    padding-left: 16px;
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 3px);
      left: 0px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: var(--v-info-base);
    }
  }
}

.v-menu__content {
  overflow-y: auto !important;
  max-height: 360px;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
    max-width: 8px;
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-gray-lighten2);
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }
}
</style>